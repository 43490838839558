import React, { Component } from "react";
import { connect } from "react-redux";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import _isEqual from "lodash/isEqual";
import { BillingInfoContainer } from "tf-checkout-react";
import { setCheckoutData } from "@actions/checkout";
import LoginModal from "@containers/auth/login_modal";
import RegistrationModal from "@containers/auth/registration_modal";
import ForgotPasswordModal from "@containers/auth/forgot_password_modal";
import { createModal, removeModal } from "@actions/ui";
import { RedirectModal } from "@components/global/timer/RedirectModal";
import { getCookieByName } from "@utils/cookies";
import { navigate } from "gatsby";
import { setTfCheckoutReactConfigs } from "../utils/utils";
import Warning from "@assets/image/warning.png";

setTfCheckoutReactConfigs();

const isWindowDefined = typeof window !== "undefined";

const billingInfoFields = [
  {
    id: 1,
    label: "Get Your Tickets",
    labelClassName: "main-header",
    fields: [
      {
        id: 1,
        groupClassname: "billing-info-container__singleField",
        groupItems: [
          {
            className: "",
            name: "account-creation",
            label: "",
            type: "",
            component: <div className="account-creation">ACCOUNT CREATION</div>,
          },
        ],
      },
      {
        id: 2,
        groupClassname:
          "billing-info-container__twoFields name-fields-container",
        groupItems: [
          {
            className: "is-half",
            name: "firstName",
            label: "First Name",
            type: "text",
            required: true,
            onValidate: () => { },
          },
          {
            className: "is-half",
            name: "lastName",
            label: "Last Name",
            type: "text",
            required: true,
            onValidate: () => { },
          },
        ],
      },
      {
        id: 2,
        groupClassname: "email-fields",
        groupItems: [
          {
            className: "",
            name: "email-info",
            label: "",
            type: "",
            component: (
              <div className="rn-warning">
                <img src={Warning} alt="Warning" />
                <div>
                  <p>
                    <span>IMPORTANT</span>: Please double check that your email
                    address is correct.
                  </p>
                  <p>
                    This is where you’ll recieve your confirmation and
                    e-tickets!
                  </p>
                </div>
              </div>
            ),
          },
          {
            className: "email-section",
            name: "email",
            label: "Email Address",
            type: "email",
            required: true,
            onValidate: () => { },
          },
          {
            className: "confirm-email-section",
            name: "confirmEmail",
            label: "Confirm Email Address",
            type: "email",
            required: true,
            onValidate: () => { },
          },
        ],
      },
      {
        id: 3,
        groupClassname: "billing-info-container__twoFields",
        groupItems: [
          {
            className: "is-half",
            name: "password",
            label: "Password",
            type: "password",
            required: true,
            onValidate: (value) =>
              value && value.length > 32
                ? "The current password may not be greater than 32 characters"
                : "",
          },
          {
            className: "is-half",
            name: "confirmPassword",
            label: "Confirm Password",
            type: "password",
            required: true,
            onValidate: () => { },
          },
        ],
      },
      {
        id: 4,
        groupClassname: "billing-info-container__singleField",
        groupItems: [
          {
            className: "date-picker-field",
            name: "holderAge",
            label: "Ticket Holder Age",
            type: "date",
            required: true,
            onValidate: () => { },
          },
        ],
      },
    ],
  },
];

const loggedUserBillingInfoFields = [
  {
    id: 2,
    label: "Get Your Tickets",
    labelClassName: "main-header",
    fields: [
      {
        id: 1,
        groupClassname: "billing-info-container__singleField",
        groupItems: [
          {
            className: "",
            name: "holderAge",
            label: "Ticket Holder Age",
            type: "date",
            required: true,
            onValidate: () => { },
          },
        ],
      },
    ],
  },
];

const ticketHoldersFields = {
  id: 2,
  label: "Ticket Holder Names",
  fields: [
    {
      id: 1,
      groupClassname: "billing-info-container__twoFields",
      groupItems: [
        {
          className: "is-half",
          name: "holderFirstName",
          label: "First Name",
          type: "text",
          required: true,
          onValidate: () => { },
        },
        {
          className: "is-half",
          name: "holderLastName",
          label: "Last Name",
          type: "text",
          required: true,
          onValidate: () => { },
        },
      ],
    },
    {
      id: 2,
      groupClassname: "billing-info-container__twoFields",
      groupItems: [
        {
          className: "is-half",
          name: "holderEmail",
          label: "Email Address",
          type: "email",
          onValidate: () => { },
        },
        {
          className: "is-half",
          name: "holderPhone",
          label: "Phone",
          type: "phone",
          onValidate: () => { },
        },
      ],
    },
  ],
};

class BillingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      holdersData: {},
      ticketsCount: null,
      allowSkipPage: false,
      initialValues: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        confirmEmail: "",
        holderFirstName: "",
        holderLastName: "",
        holderAge: "",
      },
    };
    this.query = new URLSearchParams(window?.location?.search);
    this.showDOB = this.query.get("age_required") === "true";
    this.namesRequired = this.query.get("names_required") === "true";
  }

  handleCreateAccount = () => {
    const { createModal, removeModal } = this.props;

    const modal = {
      id: "registration_modal",
      Component: () => (
        <div>
          <RegistrationModal
            removeModal={removeModal}
            openLoginModal={this.openLoginModal}
          />
        </div>
      ),
      shouldCloseOnOverlayClick: true,
      isCloseBtn: this.isMobileView,
    };

    createModal(modal, true);
  };

  handleForgotPassword = () => {
    const { createModal } = this.props;

    const modal = {
      id: "forgot_password_modal",
      Component: () => <ForgotPasswordModal />,
      shouldCloseOnOverlayClick: true,
      isCloseBtn: this.isMobileView,
    };

    createModal(modal, true);
  };

  openLoginModal = (param) => {
    const { createModal } = this.props;
    const defaultEmailValue = typeof param === "string" ? param : "";

    const modal = {
      id: "login_modal",
      Component: () => (
        <div
          style={{
            maxWidth: this.isMobileView ? "100%" : "480px",
            height: "inherit",
            display: "inline-flex",
            maxHeight: 550,
          }}
        >
          <LoginModal
            validateInput={this.validateInput}
            handleCreateAccount={this.handleCreateAccount}
            handleForgotPassword={this.handleForgotPassword}
            defaultEmailValue={defaultEmailValue}
          />
        </div>
      ),
      shouldCloseOnOverlayClick: true,
      isCloseBtn: this.isMobileView,
    };
    createModal(modal, true);
  };

  filterTicketHoldersData = (ticketsQuantity) => {
    if (+ticketsQuantity === 1) {
      this.setState({
        holdersData: {},
        ticketsCount: +ticketsQuantity,
      });
    } else if (this.namesRequired) {
      this.setState({
        holdersData: ticketHoldersFields,
        ticketsCount: +ticketsQuantity,
      });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    // For case when user will log in inside billing page
    const { ticketsCount, allowSkipPage } = this.state;
    const {
      auth: {
        me: { exist, checked, data },
      },
    } = this.props;
    const {
      auth: {
        me: { data: prevData },
      },
    } = prevProps;
    const isLoggedIn = exist && checked;

    if (
      ticketsCount === 1 &&
      !!getCookieByName("X-TF-ECOMMERCE") &&
      prevData &&
      data &&
      !_isEqual(prevData, data)
    ) {
      this.filterTicketHoldersData(ticketsCount);
    }

    if (!this.namesRequired && !_isEmpty(prevState.holdersData)) {
      this.setState({
        holdersData: {},
      });
    }

    if (
      !this.showDOB &&
      !allowSkipPage &&
      isWindowDefined &&
      window?.localStorage.getItem("user_data") &&
      isLoggedIn
    ) {
      if ((this.namesRequired && ticketsCount === 1) || !this.namesRequired) {
        this.setState({
          allowSkipPage: true,
        });
      }
    }

    if (!_isEqual(data, prevData)) {
      this.setState({ initialValues: { ...data } });
    }
  }

  render() {
    const { holdersData, allowSkipPage, initialValues } = this.state;
    const {
      browser,
      createModal,
      removeModal,
      auth: {
        me: { checked, exist },
      },
      showLoader,
    } = this.props;

    const userIsLoggedIn = exist && checked;
    const params = new URLSearchParams(window.location.search);
    const slug = params.get("slug")

    if (showLoader) {
      return null;
    }

    return (
      <div className="billing-info">
        <BillingInfoContainer
          ticketHoldersFields={holdersData}
          data={
            userIsLoggedIn ? loggedUserBillingInfoFields : billingInfoFields
          }
          initialValues={initialValues}
          buttonName="Next"
          isLoggedIn={userIsLoggedIn}
          hideLogo={true}
          accountInfoTitle="Got a Restless Nites account?"
          hideErrorsAlertSection={true}
          onLogin={this.openLoginModal}
          handleSubmit={(values, formikHelpers, eventId, res) => {
            const { setCheckoutData } = this.props;
            setCheckoutData(_get(res, "data.data.attributes"));
            const hash = _get(res.data.data.attributes, "hash");
            navigate(`/checkout/${hash}?slug=${slug}`);
          }}
          onRegisterError={(error, email) => {
            const errorEmail = _get(error, "response.data.message.email");

            if (errorEmail) {
              this.openLoginModal(email);
            }
          }}
          onSubmitError={(err) => {
            const errorMessage = _get(err, "response.data.message");
            const onClickRemove = () => {
              if (errorMessage === "The cart is expired.") {
                navigate(`/events/${slug}`);
              } else {
                removeModal("error-modal");
              }
            };

            const modal = {
              id: "error-modal",
              Component: () => (
                <RedirectModal
                  message={errorMessage}
                  browser={browser}
                  onClickOk={onClickRemove}
                />
              ),
              shouldCloseOnOverlayClick: false,
              isCloseBtn: false,
            };
            createModal(modal);
          }}
          onGetCartSuccess={(data) => {
            const ticketsQuantity = _get(
              data,
              "data.attributes.cart[0].quantity"
            );
            this.filterTicketHoldersData(ticketsQuantity);
          }}
          skipPage={allowSkipPage}
          onSkipBillingPage={(data) => {
            const hash = _get(data, "hash");
            hash && navigate(`/checkout/${hash}?slug=${slug}`);
          }}
          canSkipHolderNames={true}
          shouldFetchCountries={true}
          enableTimer
          onCountdownFinish={() => {
            const modal = {
              id: "error-modal",
              Component: () => (
                <RedirectModal
                  browser={browser}
                  onClickOk={() => {
                    removeModal("error-modal")
                    navigate(`/events/${slug}`)
                  }}
                />
              ),
              shouldCloseOnOverlayClick: false,
              isCloseBtn: false,
            };
            createModal(modal);
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    showLoader: state.ui.showLoader,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCheckoutData: (data) => {
      dispatch(setCheckoutData(data));
    },
    createModal: (modal, close_others) => {
      dispatch(createModal(modal, close_others));
    },
    removeModal: (id) => {
      dispatch(removeModal(id));
    },
  };
};

const BillingRedux = connect(mapStateToProps, mapDispatchToProps)(BillingPage);

export default BillingRedux;
